.c-navbar {
  width: 100vw;
  display: flex;
  justify-content: space-evenly;

  ons-button {
    padding: 0;
    background-color: #fff;
    color: $primary;
    font-size: 10px;
  }
}

// .tabbar__button {
//   // color: $primary;
// }

:checked + .tabbar__button {
  color: $primary;
}

.back-button {
  color: white;
}

.back-button__icon {
  fill: white;
}

.toolbar {
  background-color: rgba($primary, 0.9);
  color: white;
  padding-top: env(safe-area-inset-top);
  height: calc(44px + env(safe-area-inset-top));

  &--material {
    height: calc(56px + env(safe-area-inset-top));
  }
}

.toolbar-button {
  color: white;
}

.toolbar__title {
  color: white;
}

.toolbar + .page__background + .page__content {
  top: calc(44px + env(safe-area-inset-top));
  padding-top: 1rem;
}

.toolbar + .page--material__background + .page--material__content {
  top: calc(56px + env(safe-area-inset-top));
  padding-top: 1rem !important;
}

.tabbar {
  border-top: 1px solid #ccc;
  background-color: rgba($gray-lighter, 0.8);
  background-image: none;
  height: calc(49px + env(safe-area-inset-top));
}

.tabbar__content {
  bottom: calc(49px + env(safe-area-inset-top));
}

.tabbar__icon {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
