.c-uploading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
  }

  ons-progress-circular {
    margin-left: .5rem;
  }
}