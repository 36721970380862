.c-upcoming-events {
  background-color: transparent;
  background-image: none;

  .list-header {
    background-color: rgba($primary, 0.9);
    background-image: none;
    color: #fff;
    font-size: 1.2rem;
    text-transform: none;
    padding: 0.5rem 1rem;
    border-radius: 8px;
  }
}

.c-upcoming-event {
  display: flex;
  background-color: rgba(white, 0.8);
  color: $gray-darker;
  padding: 1rem;
  margin-top: 0.25rem;
  border-radius: 8px;

  .c-upcoming-event__content {
    width: 67%;
  }
  
  .c-upcoming-event__date {
    align-self: center;
    width: 30%;
    box-shadow: 5px 5px 10px 0 rgb(0 0 0 / 10%);
  
    .c-upcoming-event__month {
      text-align: center;
      background-color: rgba(white, 0.9);
      padding: 0.25rem 0;
    }
    
    .c-upcoming-event__day {
      padding: 0.25rem 0;
      text-align: center;
      font-size: 2rem;
      background-color: rgba($primary, 0.9);
      color: #fff;
    }
  }
}
