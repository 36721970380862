.c-appointments {
  ons-list-item {
    padding-left: 0;
    width: 100%;
  }

  ons-card {
    width: 100%;
    margin-bottom: 0;
  }

  .list-item__center {
    padding-right: 0;
  }
}

.c-appointments__name {
  font-weight: 500;
}

.c-appointments__details {
  color: $gray-dark;
}

.c-appointments__date {
  text-align: right;
}

.c-appointments__date-booked {
  color: $red;
}

.c-appointments__date-available {
  color: $primary;
}

.c-appointments__loading {
  height: 100%;
  position: relative;

  ons-progress-circular {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
  }
}

.react-datepicker__header {
  background-color: $primary;
}

.react-datepicker__current-month, .react-datepicker__day-name, .react-datepicker-time__header {
  color: #FFFFFF;
}

.react-datepicker__time-list-item--disabled {
  display: none !important;
}