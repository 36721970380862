.c-home__panel {
  // margin-top: 0;
  // border-top: 2px solid $primary;
  // border-bottom: 2px solid $primary;
  // box-shadow: 0 10px 10px 0 rgb(0 0 0 / 10%);
  padding: 2rem;
}

// .c-home__logo {
  // margin-bottom: 1rem;
// }
