.c-sidebar {
  .page__background {
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
  }

  .page__content {
    .c-sidebar-logo {
      display: block;
      padding: .3rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain !important;
      }
    }
  }

  .c-sidebar__button-group {
    position: absolute;
    bottom: 0;
    width: calc(75vw - 32px);
    margin-bottom: 16px;
  
    .button--large--cta {
      position: relative;
      display: inline-block;
    }
  }

  .list-item__center {
    flex-wrap: nowrap;
    align-items: flex-start;
    margin-top: 0.5rem;

    ons-icon {
      margin-right: 0.5rem;
    }

    p {
      margin-top: 0;
    }
  }
}