$primary: #80A6A8;
$primary-opaque: rgba(54,151,80,.25);
$gray-light: #eee;
$gray-lighter: #fcfcfc;
$gray-dark: #666666;
$gray-darker: #333333;
$green: #4DC2C2;
$red: #ED5E68;

$roboto: 'Roboto', sans-serif;
