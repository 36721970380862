html {
  overflow: hidden;
}

body {
  -webkit-overflow-scrolling: auto;
  color: white;
}

html,
body {
  min-height: 100%;
	min-height: calc(100% + constant(safe-area-inset-top));
	min-height: calc(100% + env(safe-area-inset-top));
}

#app {
  // position: absolute;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.page {
  color: $gray-darker;
}

.page__background {
  background: url('../images/wact-banner.jpeg') no-repeat center;
  background-size: cover;
  height: 100%;
}

.page__content {
  padding: 1rem;

  h1,
  h2,
  h3,
  h4 {
    font-family: -apple-system, 'Helvetica Neue', 'Helvetica', 'Arial', 'Lucida Grande', sans-serif;
  }
}

.page__content .page__content {
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.page__content .c-sidebar .page__content {
  background-image: none !important;
}

.c-donate,
.c-newsletter {
  .page__content {
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }
}

.button {
  background-color: $primary;

  &--secondary {
    background-color: $gray-dark;
  }
}

.button--large--cta {
  background-color: $primary;

  &--secondary {
    background-color: $gray-dark;
  }
}

:root {
  --text-color: #000;
}

.progress-circular--material__primary {
  stroke: white;
}

.progress-circular__primary {
  stroke: white;
}

ons-modal {
  background-color: none;
}

.alert-dialog-button {
  color: #fff;
}

.card {
  margin: 0;
  margin-bottom: 1rem;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  padding: 1.5rem;
}

h1 {
  font-weight: 700;
} 

.progress-bar {
  padding-top: .2rem;
  padding-bottom: .2rem;

  background-color: #FFFFFF;

  &__primary {
    background-color: $primary;
  }
}

.list {
  background-color: transparent;
}

ons-checkbox {
  margin-top: 14px;
  margin-bottom: 14px;
}

:checked + .checkbox__checkmark:before {
  background-color: $primary;
}

.page-loading {
  height: 100%;
  position: relative;

  ons-progress-circular {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
  }
}

// Toast 
.ct-text {
  font-family: $roboto;
}