.c-listing {
  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  &__subheading {
    color: #696969;
    margin-bottom: 4px;
    margin-top: 4px;
  }

  &__item {
    margin-top: 1px;
    margin-bottom: 7px;
  }

  &__icon-group {

    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .MuiSvgIcon-root {
      margin-right: .5rem;
    }

    &__text {
      flex-direction: column;
    }
  }
}