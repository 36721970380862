.c-profile {
  .c-profile__subheading {
    color: #696969;
    margin-bottom: 4px;
  }

  .c-profile__item {
    margin-top: 1px;
    margin-bottom: 7px;
  }
}


.c-profile-checklist {
  h5 {
    font-weight: bold;
  }

  ons-list-item {
    padding-left: 0;
  }
}