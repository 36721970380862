.c-login__logo {
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin: 0 auto;
    max-width: 350px;
  }
}

.c-login__reset {
  text-align: center;
  color: #000;
  text-decoration: none;
}