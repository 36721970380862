.c-form {
  
  &--h100 {
    height: 100%;
  }

  .c-form__transition {
    height: 100%;
  }

  .c-form__button-group {
    margin-top: auto;
  }

  .c-form__container {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  img {
    width: 100%;
    height: auto;
    margin-top: 1rem;
  }

  &__text--required {
    font-style: italic;
    color: #696969;
  }

  &__icon {
    display: flex;
    flex-direction: row;
    align-items: center;

    .MuiSvgIcon-root {
      margin-bottom: 16px;
    }
  }

  .c-form__label {
    margin-top: 0;
    color: #868686;
    margin-bottom: 4px;
  }

  .c-form__row {  
    text-align: center;
    width: 100%;
    height: 57px;
    margin-top: .6rem;

    &.c-form__row--textarea {
      height: 150px;
    }
  
    ons-input {
      box-sizing: border-box;
      background-color: #fff;
      width: 100%;
      padding: 0.25rem 0.5rem;
      border-radius: 4px;
      box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
    }

    textarea {
      display: block;
      clear: both;
      box-sizing: border-box;
      background-color: #fff;
      width: 100%;
      height: 100%;
      padding: 0.25rem 0.5rem;
      border-radius: 4px;
      box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
      border: none;
      font-family: -apple-system, 'Helvetica Neue', 'Helvetica', 'Arial', 'Lucida Grande', sans-serif;
      font-weight: 400;
      font-size: 15px;
      min-height: 100px;
      margin-bottom: 0px;
    }
  
    ons-select {
      box-sizing: border-box;
      background-color: #fff;
      padding: 0.25rem 0.5rem;
      width: 100%;
      border-radius: 4px;
    }
  
    ons-radio {
      box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
      margin-left: 0 !important;
      border-radius: 4px;
    }
  
    i {
      color: #ff0000;
      font-size: .9rem;
    }
  
    p {
      text-align: left;
      margin-bottom: 10px;
      margin-top: 30px;
    }
  
    .c-form__card {
      display: inline-block;
      width: 100%;
      background-color: #fff;
      padding: 0.75rem 0.5rem;
      border-radius: 4px;
      box-sizing: border-box;
    }
  
    ons-list-header {
      background-color: $primary;
      color: #fff;
      text-transform: none;
      font-size: 1rem;
      padding-top: .2rem;
      padding-bottom: .4rem;
    }

    // &--material {
    //   ons-input {
    //     padding-top: .9rem;
    //     padding-bottom: .4rem;
    //   }
    // }
  }
  
  .c-form__family {
    display: inline-block;
    background-color: #fff;
    padding: .5rem;
    border-radius: 10px;
    min-width: 92vw;
    max-width: 92vw;
  
    p {
      color: #1f1f21;
      margin-bottom: 1.7rem;
    }
  
    ons-button {
      margin-right: .4rem;
    }
  
    ons-input {
      max-width: 95%;
    }
  
    ons-select {
      max-width: 95%;
      padding-left: 0;
      padding-right: 0;
      padding-top: .3rem;
      padding-bottom: .3rem;
    }
  }
  
  .c-form__familymember {
    width: 100%;
  
    ons-icon {
      max-width: 30%;
      float: left;
      margin-right: .4rem;
    }
  
    p {
      max-width: 30%;
      float: left;
      margin: .4rem;
      margin-left: .2rem;
    }
  
    button {
      float: right;
      background-color: #fff;
      color: #000;
      font-style: normal;
    }
  }
  
  .c-form__error {
    text-align: left;
    margin-left: .1rem;
    color: red;
  }
  
  .c-form__radiogroup {
    text-align: left;
  
    ons-radio {
      background-color: #fff;
      margin-left: .2rem;
    }
  
    ons-row {
      margin-bottom: .6rem;
    }
  
    .c-form__radiolabel {
      min-width: 85%;
      width: 85%;
    }
  
    .radio-button--material {
      background-color: transparent;
      box-shadow: none;
    }
  }

  &__photo {
    display: grid; 
    grid-template-columns: 1fr 1fr 5fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    z-index: 0;

    p {
      color: #ffffff;
    }

    ons-icon {
      color: $green;
    }

    &__controls {
      position: relative;
      grid-column-start: 1;
      grid-column-end: 6;
      grid-row-start: 3;
      z-index: 2;
      margin-top: auto;
      margin-bottom: auto;

      &__buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-left: .2rem;
        margin-right: .2rem;
        z-index: 3;

        ons-button {
          margin-left: .1rem;
          margin-right: .1rem;
        }
      }
    } 
  
    &__image {
      grid-column-start: 1;
      grid-column-end: 6;
      grid-row-start: 1;
      grid-row-end: 4;
      background-position: right bottom;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 1;

      &__selected {
        filter: brightness(50%);
      }
    }
  }
}

.step-enter {
  opacity: 0;
  transform: scale(0.9);
}
.step-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.step-exit {
  opacity: 1;
}
.step-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.text-input--material__label {
  margin-left: .5rem;
  margin-top: .3rem;
}

.text-input--material {
  padding-top: .9rem;
  padding-bottom: .9rem;
}

.react-datepicker,
.react-datepicker--monthonly,
.react-datepicker--timeonly, 
.react-datepicker__time-container, 
.react-datepicker__month-container,
.react-datepicker__time-list,
.react-datepicker__time-box {
  width: 100% !important;
}

.react-datepicker__time-list-item {
  min-height: 45px;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.c-form__timeslot {
  .react-datepicker__time-list-item--selected {
    background-color: $primary !important;
    color: #FFFFFF !important;
  }
}

.timeslot-selected {
  background-color: $primary !important;
  color: #FFFFFF !important;
}

.c-form__checkbox-group {
  .btn-group {
    display: flex;
    flex-wrap: wrap;
  }

  .btn {
    display: flex;
    width: 50%;
    margin-bottom: 1rem;
    word-break: break-word;
  }

  .checkbox {
    margin: 0;
    margin-right: 0.5rem;
  }
}
