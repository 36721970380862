.c-add-to-home {
  padding: 1.5rem 1rem;
  text-align: center;

  p:last-child {
    margin-bottom: 0;
  }
}

.c-add-to-home__close {
  position: absolute;
  top: 0.75rem;
  right: 1.25rem;
}

.c-add-to-home__logo {
  width: 80px;
  border-radius: 0.5rem;
  box-shadow: 5px 5px 10px 0 rgb(0 0 0 / 10%);
}

.c-add-to-home__icon {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
