.u-text-center {
  text-align: center;
}

.u-mb-0 {
  margin-bottom: 0;
}

.u-mb-05 {
  margin-bottom: .5rem !important;
}

.u-mb-1 {
  margin-bottom: 1rem !important;
}

.u-mb-2 {
  margin-bottom: 2rem !important;
}

.u-mb-3 {
  margin-bottom: 3rem !important;
}

.u-mb-4 {
  margin-bottom: 4rem !important;
}

.u-mb-50px {
  margin-bottom: 50px !important;
}

.u-m-0 {
  margin: 0 !important;
}

.u-mt-0 {
  margin-top: 0 !important;
}

.u-mt-25 {
  margin-top: .25rem !important;
}

.u-mt-05 {
  margin-top: .5rem !important;
}

.u-mt-1 {
  margin-top: 1rem !important;
}

.u-mt-2 {
  margin-top: 2rem !important;
}

.u-mt-3 {
  margin-top: 3rem !important;
}

.u-mt-4 {
  margin-top: 5rem !important;
}

.u-mt-5 {
  margin-top: 5rem !important;
}

.u-mt-70px {
  margin-top: 70px !important;
}

.u-mb-70px {
  margin-bottom: 70px !important;
}

.u-ml-025 {
  margin-left: 0.25rem;
}

.u-ml-05 {
  margin-left: .5rem !important;
}

.u-ml-1 {
  margin-left: 1rem !important;
}

.u-mr-025 {
  margin-right: 0.25rem;
}

.u-mr-05 {
  margin-right: .5rem;
}

.u-mr-075 {
  margin-right: .75rem;
}

.u-mr-1 {
  margin-right: 1rem !important;
}

.u-p-1 {
  padding: 1rem;
}

.u-bg {
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: -1rem;
  padding: 1rem;
}

.u-bg--white {
  background-color: white;
}

.u-bg--gray {
  background-color: rgba($gray-light, 0.7);
  color: $gray-darker;
}

.u-bg--red {
  background-color: $red !important;
}

.u-bg--green {
  background-color: $green !important;
}

.u-text--green {
  color: $green !important;
}

hr {
  border: 0;
  border-top: 3px solid $primary;
  width: 60%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.u-text-itallic {
  font-style: italic !important;
}

.u-h-200 {
  height: 200px !important;
}

.u-w-50 {
  max-width: 50%;
  width: 100%;
}

.u-flex-row-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}